<template>
  <BaseLayout>
    <SubHeader></SubHeader>
    <div class="container">
      <h1 class="text-center">Change Equipment Plan</h1>
      <div style="text-align: center; margin: 20px 0;" v-if="loading"><a-spin /></div>
      <div class="card-deck mb-3 text-center" style="margin: 20px 0;" v-if="loading === false">
        <div class="card mb-4 box-shadow">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">{{plans.basic.name}}</h4>
          </div>
          <div class="card-body">
            <div v-if="$store.state.is_trainer_package_subscriber">
              <h1 class="card-title pricing-card-title"><span style="text-decoration: line-through;">${{plans.basic.prices.monthly.price}}</span> $0</h1>
              <h1 class="card-title pricing-card-title">PER MONTH</h1>
              <p style="font-weight: bold;">Included with your Trainer/Breeder Package subscription</p>
            </div>
            <div v-else>
              <h1 class="card-title pricing-card-title">${{plans.basic.prices.monthly.price}}</h1>
              <h1 class="card-title pricing-card-title">PER MONTH</h1>
            </div>
            <p>
              The Basic Plan includes a map pin of business or horse location and the listing is added to the &quot;list&quot; option on the search function. The map pin and listing will show up in a search if they put your specific location (by region or zip code) in their &quot;search by&quot; method as well as if they open their search to &quot;all locations.&quot; <i>(Please download the Mane Street Market app or visit www.manestreetmarket.com to see map pins and list options.)</i>
            </p>          
          </div>
          <div class="card-footer">
            <button type="button" class="btn btn-lg btn-block btn-primary" @click="upgrade(plans.basic.code)" :disabled="item.package_name === plans.basic.code">Upgrade</button>
          </div>
        </div>
        <div class="card mb-4 box-shadow">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">{{plans.regional.name}}</h4>
          </div>
          <div class="card-body">
            <div>
              <h1 class="card-title pricing-card-title">${{plans.regional.prices.monthly.price}}</h1>
              <h1 class="card-title pricing-card-title">PER MONTH</h1>
            </div>
            <p>
              The Premium Plan includes the benefits of the Basic Listing (map pin/list on both website and app) plus a scrolling ad on the website which gives upgraded visibility. The advertisement rotates on all search options on desktop and pops up on mobile web. <i>(Please visit www.manestreetmarket.com and click on Horses for Sale or Service Providers to see website ads.)</i> Additional perk includes being featured at least once in the bi-monthly Mane Street Market email newsletter that goes to over 5,000 equestrians. 
            </p>          
          </div>
          <div class="card-footer">
            <button type="button" class="btn btn-lg btn-block btn-primary" @click="upgrade(plans.regional.code)" :disabled="item.package_name === plans.regional.code">Upgrade</button>
          </div>
        </div>
        <!-- <div class="card mb-4 box-shadow">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">{{plans.national.name}}</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">${{plans.national.prices.monthly.price}} <small class="text-muted">/ mo</small></h1>
            <p>
              App and Website Advertiser includes benefits of Website Advertiser and Basic Listing plus rotating banner advertisement on app. Ads on web and mobile apps include a hyperlink to advertiser's website. This option provides maximum exposure to the MSM audience. Please contact support@manestreetmarket.com for ad specs before choosing this option. <i>(Please download the Mane Street Market app to see current app banner ads scrolling on the bottom of the screen.)</i>
            </p>          
          </div>
          <div class="card-footer">
            <button type="button" class="btn btn-lg btn-block btn-primary" @click="upgrade(plans.national.code)" :disabled="item.package_name === plans.national.code">Upgrade</button>
          </div>
        </div> -->
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layouts/Base.vue';
import SubHeader from '@/components/SubHeader.vue';
import plans from '@/constants/plans';
import axios from "axios";
import {db} from "@/firebase";
import ASpin from 'ant-design-vue/lib/spin';
import 'ant-design-vue/lib/spin/style/index.css';

export default {
  components: {
    BaseLayout,
    SubHeader,
    ASpin
  },
  data() {
    return {
      loading: false,
      plans: plans,
      item: {}
    }
  },
  async mounted() {
    document.title = 'Change Equipment Plan - Mane Street Market';
    window.scrollTo(0, 0);
    await this.loadListing();
  },
  methods: {
    async loadListing() {
      this.loading = true;
      try {
        let response = await axios.get('/api/equipments/' + this.$route.params.id);
        this.item = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async upgrade(packageName) {
      let plan = this.plans[packageName];
      this.loading = true;
      try {
        await axios.post('/api/change-equipment-plan', {
          id: this.$route.params.id,
          package_name: plan.code,
          price_id: plan.prices.monthly.id
        });
        let userProfile = await db.collection('Users').doc(this.$store.state.user.uid).get()
        this.$store.dispatch('setProfile', userProfile.data());
        this.$router.push(`/my-equipment?upgrade_completed=1`);
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .pricing-card-title {
    font-size: 28px;
  }
  
  .listing-link {
    margin: 0 !important;
    margin-top: 8px !important;
  }
</style>
